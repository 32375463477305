.campaign-header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;

  &__headline {
    display: flex;
    align-items: center;
  }

  &__infos {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    & > *  {
      padding-right: 15px;
      margin-right: 15px;
      border-right: 1px solid $grey-20;
    }

    & > *:last-child  {
      padding-right: 0;
      margin-right: 0;
      border-right-width: 0;
    }
  }

  &__info-item {
    color: $grey-60;
    font-family: $roboto;
    font-size: 1rem;

    & > dt {
      display: block;
      margin-bottom: 8px;
    }

    & > dd {
      margin: 0;
    }
  }

  &__campaign-code {
    display: inline-flex;
  }
}

@include device-is('mobile') {
  .campaign-header-title {
    flex-direction: column;
    align-items: initial;

    &__headline {
      margin-bottom: 16px;
      justify-content: center;
      width: 100%;
    }

    &__infos {
      justify-content: center;
    }
  }
}
