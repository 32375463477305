.icon {

  &--margin {
    margin: 0 8px;
  }

  &--warning {
    color: $warning;
  }
}
