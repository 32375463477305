.participants-by-status {
  height: 240px;

  &__legend {
    list-style: none;
    padding-left: 0;
    padding-top: 16px;
    margin: 0;
    font-size: 0.875rem;

    > li {
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 8px;
      padding-left: 24px;
      color: $grey-70;

      &:last-child {
        padding-bottom: 0px;
      }
    }
  }

  &__legend-tooltip {
    margin-left: 8px;
    color: $grey-30;
  }

  &__legend-view {
    border-radius: 14px;
    margin: 0 8px;
  }

  &__loader {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--chart {
      height: 240px;
      width: 240px;
    }

    &--legend {
      height: 20px;
      width: 100%;
      margin-top: 8px;
    }
  }
}
