$breakpoints: (
  'mobile': (max-width: 767px),
  'tablet': (min-width: 768px),
  'desktop': (min-width: 980px),
  'large-screen': (min-width: 1261px),
) !default;

@mixin device-is($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
