.copy-paste-button {
  &__clipboard {
    margin: -10px 6px 0 6px;
  }

  &__tooltip {
    .pix-tooltip__content {
      margin-bottom: 6px;
    }
  }
}
