// See https://zeroheight.com/8dd127da7/p/839645-couleurs/b/21317a

// primary
$blue: #3D68FF;
$yellow: #FFBE00;
$blue-zodiac: #505F79;
$black: #07142E;
$white: #ffffff;
// secondary
$blue-hover: #3257D9;
$dark-blue-pro: #1A38A0;
$dark-blue-orga: #213371;
$dark-green-certif: #1A8C89;
$dark-green-certif-light: adjust-color($dark-green-certif, $alpha: -0.9);
$dark-orga: #0095C0;
$green: #038A25;
$green-hover: darken($green, 4%);
$orga: #00DDFF;
$purple: #8A49FF;
$red: #D63F00;
$red-hover: darken($red, 4%);
// gradients
$pix-orga-old-gradient: linear-gradient(0deg, #0D7DC4 0%, #213371 100%);
$pix-yellow-gradient: linear-gradient(135deg, #FFBE00 0%, #FF9F00 100%);
// light
$grey-5: #FAFBFC;
$grey-10: #F4F5F7;
$grey-15: #EAECF0;
$grey-20: #DFE1E6;
$grey-22: #C1C7D0;
$grey-25: #A5ADBA;
// medium
$grey-30: #97A0AF;
$grey-35: #8993A4;
$grey-40: #7A869A;
$grey-45: #6B778C;
$grey-50: #5E6C84;
$grey-60: #505F79;
// dark
$grey-70: #344563;
$grey-80: #253858;
$grey-90: #172B4D;
$grey-100: #091E42;
$grey-200: #07142E;
// gradients domain
$information-gradient: linear-gradient(180deg, #F24645 0%, #F1A141 100%);
$content-gradient: linear-gradient(0deg, #52D987 0%, #1A8C89 100%);
$communication-gradient: linear-gradient(0deg, #12A3FF 0%, #3D68FF 100%);
$security-gradient: linear-gradient(0deg, #FF3F93 0%, #AC008D 100%);
$environment-gradient: linear-gradient(180deg, #5E2563 0%, #564DA6 100%);
// solids
$environment-dark: #5E2563;
$environment-light: #564DA6;
$communication-dark: #3D68FF;
$communication-light: #12A3FF;
$content-dark: #1A8C89;
$content-light: #52D987;
$information-dark: #f24645;
$information-light: #F1A141;
$security-dark: #AC008D;
$security-light: #FF3F94;
$pink-alert-dark: #A71E1A;

// status
$error: #FF4B00;
$success: #57C884;
$warning: #FFBE00;

// Obsolete with new design
$chambray: #325792;
$curious-blue: #2b89c8;
