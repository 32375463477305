.campaign-list {
  padding: 0;

  &__link {
    color: $grey-80;
    display: block;
    padding: 20px 24px;
    text-decoration: none;
  }

  &__item {
    margin-bottom: 18px;
  }

  &__table-header-background {
    background: $grey-10;
    box-shadow:0px 0px 0px 4px $white inset;
  }
}
