.terms-of-service-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 800px;
  background: $pix-orga-old-gradient;
  justify-content: center;
  align-items: center; // XXX do not remove: ugly fix for ugly IE
}

.terms-of-service-form {
  margin: auto;
  max-width: 800px;
  padding: 35px 0;
  justify-content: center;

  background-color: $white;
  border-radius: 10px;

  @include device-is('mobile') {
    width: 95%;
  }

  &__logo {
    margin-bottom: 20px;

    & img {
      display: flex;
      margin: auto;
    }

  }

  &__title {
    margin-bottom: 30px;
    text-align: center;
    color: $grey-100;
    font-family: $roboto;
    font-size: 1.15em;
    font-weight: 300;
  }

  &__line {
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
    border: none;
    background-color: $grey-20;
  }

  &__text {
    height: 400px;
    margin: 0 60px 20px;
    padding: 0 35px;
    overflow-y: scroll;
    overflow-x: hidden;
    font-family: $open-sans;

    @include device-is('mobile') {
      margin: 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 100px;

    > button {
      margin-left: 24px;
    }

    @include device-is('mobile') {
      flex-direction: column;
      align-items: center;
      margin: 15px 10px 0 10px;

      > button {
        margin-left: 0px;
      }
    }
  }

  &__submit {
    margin-left: 10px;

    @include device-is('mobile') {
      margin: 10px 0 0 0;
    }

    &:focus,
    &:hover,
    &:focus-within {
      border: 2px solid white;
      box-shadow: 0 0 0 2px $blue;
      padding: 0;
    }
  }

}

.terms-of-service-form-text {

  &__article-title {
    font-size: 1.15em;
  }

  &__article-subtitle {
    font-size: 1em;
  }
}
