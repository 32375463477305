.previous-page-button {
  display: flex;
  flex: auto;
  align-items: center;

  &__return-button {
    margin-bottom: 0px;
    margin-right: 8px;
  }
}
