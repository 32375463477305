.download-file {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  padding: 0.5em 0;
  top: 0;
  z-index: 1;

  &__button {
    width: fit-content;
  }
}
.preselect-tube-table {
   .is-responsive {
     color: $green;
   }
   .not-responsive {
     position: absolute;
     top: 50%;
     left: calc(50% + 12px);
     transform: translate(-50%, -50%);
     color: $red;
   }
}

.preselect-target-profile {
  .pix-collapsible {
    &__title {
      align-items: center;
      font-family: $open-sans;
      font-size: 1.5rem;
      font-weight: 500;
      position: relative;
      padding-left: 2rem;

      &:before {
        content: "";
        position: absolute;
        top: 10%;
        left: 1rem;
        border-left: 3px solid;
        height: 80%;
      }

      &.jaffa:before {
        border-color: $information-light;
      }

      &.emerald:before {
        border-color: $content-light;
      }

      &.cerulean:before {
        border-color: $communication-light;
      }

      &.wild-strawberry:before {
        border-color: $security-light;
      }

      &.butterfly-bush:before {
        border-color: $environment-light;
      }
    }
    &__content {
      padding: 0;
      cursor: auto;

      h2 {
        font-family: $open-sans;
        font-size: 1.125rem;
        padding: 1rem;
        margin: 0;
        color: $grey-90;
      }

      table {
        color: $grey-70;
      }

      th {
        font-weight: 500;
        color: $grey-100
      }

      th, td {
        vertical-align: middle;
      }
      td.table__column--center,
      th.table__column--center {
        text-align: center;
      }
      thead {
        background: $grey-10;
      }
    }
  }
}
