.dropdown {
  position: relative;
  display: inline-block;

  &__content {
    background-color: $white;
    border-radius: 4px;
    box-shadow: 1px 1px 3px 1px rgba($black, 0.13), 0 1px 1px 0 rgba($black, 0.08);
    overflow: hidden;
    position: absolute;
    padding: 0;
    margin: 0;
    z-index: 1;
  }

  &__item {
    display: flex;

    &--link {
      padding: 0;

      > button {
        border: none;
        background-color: transparent;
        font-family: $roboto;
        text-align: start;
      }

      > a, > button {
        color: $grey-60;
        padding: 12px 16px;
        width: 100%;
        height: 100%;
        font-size: 0.875rem;
        cursor: pointer;
        transition: background-color 0.1s linear;

        &:hover,
        &:active,
        &:focus {
          background-color: $grey-10;
          color: $grey-60;
          text-decoration: none;
        }

        &:focus, &:focus-visible {
          border-radius: 4px;
          box-shadow: inset 0px 0px 0px 1.6px $blue;
          outline: none;
        }
      }
    }
  }
}
