.app-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__text {
    display: block;
    font-size: 1.2rem;
    padding: 20px;
    text-align: center;
  }

  img {
    height: 150px;
    width: 150px;
  }
}
