.campaign-archived-banner {
  font-family: $open-sans;
  font-weight: 600;
  background: $blue-zodiac;
  display: flex;
  padding: 8px 10px;
  margin-bottom: 24px;
  border-radius: 6px;
  color: $white;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;

  &__text {
    display: flex;
    font-size: 1rem;
    align-items: center;
  }

  &__icon {
    margin-right: 24px;
    margin-left: 16px;
    font-size: 1.6rem;
  }

  &__unarchive-button {
    background: $blue-zodiac;
    border: 2px solid $white;
    margin-left: auto;
    &:hover {
      opacity: .8;
      background: $blue-zodiac;
    }
    &:focus {
      background: $blue-zodiac;
    }
  }
}
