.campaign-header-tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0;

  &__export-button {
    display: flex;
    align-items: center;
    padding-right: 10px;
  }
}
