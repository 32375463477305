.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  margin: 0 35px;
  border-top: 1px solid $grey-20;

  @include device-is('desktop') {
    flex-direction: row;
  }

  &__navigation {
    display: flex;
    flex-direction: column;

    @include device-is('desktop') {
      flex-direction: row;
    }

    &-list {
      list-style: none;
      display: flex;
      padding: 0;

      li {
        align-self: center;
        display: inline;
        padding: 0;
      }
    }
  }

  &__copyrights {
    color: $grey-50;
    cursor: default;
    font-size: 0.75rem;
    font-family: $roboto;
    padding: 16px 0;
  }
}

.footer-navigation {
  &__item {
    margin-right: 3px;
    color: $grey-60;
    font-size: 0.8125rem;
    font-weight: 500;
    font-family: $roboto;
    text-decoration: none;

    @include device-is('desktop') {
      margin-right: 12px;
    }

    &.active {
      color: $blue;
    }

    &:focus {
      color: $grey-50;
    }

    &:hover {
      cursor: pointer;
      color: $blue;
    }
  }
}
