.participant-header {
  width: 100%;

  &__title {
    display: flex;
    align-items: center;
    margin: 16px 0;
  }

  &__information {
    margin-bottom: 1em;
  }
}

.participant-header-content {
  &__left-wrapper {
    border-right: 1px solid $grey-20;
    width: 60%;
    margin: 0 0 0 3%;
    flex-shrink: 1;
  }

  &__right-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-shrink: 0;
    flex-grow: 1;
    min-width: 370px;
    max-width: 600px;

    .participant-content--large {
      flex-shrink: 0;
    }
  }
}
