.panel {
  border-radius: 4px;
  border: none;
  box-shadow: $shadow-base;
  background-color: $white;

  &--header {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    margin-bottom: 24px;
  }
}

.panel-header__headline {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid $grey-20;
  padding-bottom: 24px;

  .panel-header-title {
    margin: 0;
  }
}

.panel-header__body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 16px;
}

.panel-header__data {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0;
  margin: 0;
  font-family: $roboto;

  &--highlight {
    background-color: $grey-10;
    border-radius: 8px;
  }
}

.panel-header-data {
  &__content {
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 16px;
    border-right: 1px solid $grey-20;
  }

  &__content:last-child {
    border-right: none;
  }
}

.panel-header-data-content {
  &__badges {
    display: flex;
    align-items: center;
    flex-direction: row;

    img {
      height: 40px;
    }

    & > :not(:last-child) {
      padding-right: 2px;
    }
    & > :not(:first-child) {
      padding-left: 2px;
    }
  }

  &__label {
    margin-bottom: 8px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__value {
    margin: 0;
  }


  &__stages {
    display: flex;
    align-items: center;

    .pix-stars svg {
      width: 26px;
      height: 26px;
    }
  }

  &__progress-bar {
    border-right: none;
  }

  &__mastery-percentage {
    padding-left: 0;
  }
}

.navbar {
  display: flex;
  width: 100%;
  min-height: 60px;
  border: none;
  padding: 0 8px;
  display: flex;

  &-item {
    padding: 0 24px;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    outline: none;
    cursor: pointer;
    color: $grey-50;
    font-family: $roboto;
    font-size: 0.9rem;
    font-weight: 500;
    transition: all ease-in-out .2s;
    text-decoration: none;

    &:hover, &:focus {
      color: $blue;
    }

    &-separator {
      height: 30px;
      border-left: 1px solid $grey-25;
      margin: 15px 0px;
    }
  }

  &-item.active {
      border-bottom: 2px solid $blue;
      color: $blue;
  }
}

@include device-is('mobile') {
  .navbar{
    height: auto;
    padding: 0;
    width: 100%;

    &-item {
      width: 100%;
      height: 48px;
    }

    &-item.active {
      border-bottom-width: 0px;
      background-color: lighten($blue, 32%);
    }
  }

  .panel-header__body {
    flex-direction: column;
  }

  .panel-header__data {
    flex-direction: column;
    align-items: flex-start;

    &--highlight {
      flex-direction: column;
      align-content: flex-start;
      padding: 16px;
      margin-top: 16px;
    }
  }

  .panel-header-data {
    &__content {
      padding: 0;
      border-left: none;
      margin-bottom: 16px;
    }


    &__content:last-child {
      margin-bottom: 0px;
    }
  }
}
