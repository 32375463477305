.sidebar {
  display: flex;
  flex-direction: column;
  width: $app-sidebar-width;
  min-width: 100px;
  background-color: $dark-blue-orga;

  &__logo {
    padding: 24px;

    img {
      height: 60px;
    }
  }
}

.sidebar-nav {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    align-items: center;
    height: 40px;
    color: $white;
    font-family: $roboto;
    font-size: 0.875rem;
    text-decoration: none;
    padding-left: 24px;
    border-left: 3px solid transparent;

    &:hover {
      background-color: $chambray;
    }

    &.active {
      border-left: 3px solid $yellow;
      background-color: $chambray;
    }
  }

  &__item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    width: 8%;

    > img {
      height: 1rem;
      margin: 0;
      width: 100%; //useful for IE
    }
  }

  &__documentation-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

@include device-is('mobile') {
  .sidebar {
    width: 100%;
  }
}
