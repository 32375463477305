.page-title {
  font-family: $open-sans;
  font-weight: 300;
  font-size: 2.5rem;
  color: $grey-90;
  margin-top: 0;
}

.page-sub-title {
  font-family: $open-sans;
  font-weight: 300;
  font-size: 1.5rem;
  color: $grey-60;
}

.form-title {
  font-family: $open-sans;
  font-weight: 300;
  font-size: 1.9rem;
  color: $grey-100;
}

.panel-header-title {
  font-family: $open-sans;
  font-weight: 300;
  font-size: 2rem;
  color: $grey-90;
}

.back-title {
  font-family: $open-sans;
  font-weight: 400;
  font-size: 1.25rem;
  color: $grey-90;
  margin: 0;
}
