@mixin colorize($color, $percentageDarkenForColor, $percentageLightenForBackground) {
  color: darken($color, $percentageDarkenForColor);
  background-color: lighten($color, $percentageLightenForBackground);
}

.indicator-card {
  display: flex;
  width: 100%;
  background-color: $white;
  border-radius: 8px;
  box-shadow: $shadow-base;
  min-height: 112px;
  padding: 0;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px 0 0 8px;
    background-color: $grey-20;
    color: $grey-60;
    min-width: 96px;
    font-size: 1.5rem;

    &--blue { @include colorize($blue, 0%, 32%); }
    &--purple { @include colorize($purple, 6%, 32%); }
    &--green { @include colorize($green, 0%, 70%); }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $grey-60;
    margin: 16px 32px;
  }

  &__title {
    display: flex;
    align-items: center;
    font-family: $roboto;
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: 8px;
  }

  &__value {
    font-family: $open-sans;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2rem;
    margin: 0;
  }

  &__tooltip {
    font-weight: 400;
  }

  &__tooltip-icon {
    color: $grey-30;
    margin: 0 8px;
  }
}
