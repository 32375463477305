.profile {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-user {
  &__certifiable {
    margin-left: 8px;
  }
}

.profile-competences {
  margin-bottom: 24px;
  padding: 0;
}

